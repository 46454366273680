*{
  font-family: 'Montserrat', sans-serif;
}
.inv-box{
  margin: auto;
  padding: auto;
}

.parent .Table-container{
  width: 900px;
  height: 1500px;
  margin-top: 10px;
  margin: auto;
  display:flexbox;

  
}
.parent .Table-container-eight
.parent .Table-container-one h6 {
  margin: auto;
  text-align: left;
  margin-top: -1px;
  border: solid black 1px;
  width: 97%;
  padding: 5px;
  text-align: left;
  float: left;
  font-size: 14px;
  margin-bottom: -1px;
}

.parent table, .parent th, .parent td {
  border-collapse: collapse;
}


.parent .Table-container-two{
  margin: auto;
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  border: 1px solid black;
  border-right: none;
  border-left: none;
  width: 100%;
  margin-left: 0px;
  padding-bottom: 0px;
  text-align: right;
}

 .parent #table1 th  {

  padding-bottom: 34px;
  border-color: black;
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 120px;
  padding-right: 10px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-right: 1px solid black;
  font-family: sans-serif;
   

}

.parent .Table-container-three {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid black;
  border-right: none;
  padding: 5px;
  width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  border-top: none;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: space-between;
}
.parent #table2 th,.parent td {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-right: 1px solid black;
  font-family: sans-serif;
  padding-left: 170px;
  font-variant: inherit;
  
  
}

.parent .Table-container-four{
  display: flex;
  flex-direction: row;
  margin-top:5px ;
}
.parent .container-four-left{
  margin-top: -6px;
  padding: 0px;
  border-left: solid black 1px;
  border-right: solid black 1px;
}

.parent #table3 th{
  border-top: 1px solid black;
  text-align: left;
  padding:5px;
  border-left: none; 
 
}
.Table-container-two p:first-child {
  border: solid black 1px;
  padding: 30px;
  margin-top: -12px;
  margin-left: -11px;
  border-bottom: none;
  margin-bottom: -4px;
  border-top: none;
}
.parent .Table-container-two p img{
  height: 80px;
  object-fit: cover;
}
.parent .container-four-right{
  margin-left: 5px;
  margin-top: 10px;
  text-align: left;
}

.parent #table4 tr,.parent th{
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-right: 1px solid black;
  padding-top: 28px;
  text-align: left;
  font-variant: inherit;
  
}

.parent .Table-container-five { 
  margin-top: -1px;
  
}

.parent #table5 th{
  padding-left: 30px;
  
}
.parent .Table-container-six-invoice {
  margin-top: -1px;
  width: 100%;
  font-size: 14px!important;
}
.parent .Table-container-six {
  margin-top: -1px;
  width: 120.5%;
  font-size: 14px!important;
  max-width: 100%;
}
td {
  font-size: 14px;
}
th{
  font-size: 14px;
}
.parent .Table-container-six th{
  font-size: 14px;
}
.parent #table6 th{
  padding-left: 80px;
}

.parent .Table-container-seven{

  margin-top: 10px;
}

.parent #table7 th{
  padding-left: 260px;
  text-align: left;
  font-variant: inherit;

}
.parent .Table-container-eight{
  margin-top: -1px;

}

.parent #table8 th,.parent tr{
  padding-left: 200px;
  text-align: left;
}

.parent table th {
  font-size: 16px;
  font-family: sans-serif;
  padding: 5px;
}


table#table4 {
  margin-top: -1px!important;
  margin-left: -6px;
  border-left: none!important;
  width: 100%!important;
  height: 100%;
}
.parent td{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 10px;
  border-bottom: 1px solid black;
  font-family: sans-serif;
  padding: 5px!important;
}

#invoicetype {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  font-size: 20px!important;
  margin-bottom: 22px;
}
.parent .Table-container {
  display: flexbox;
  height: auto;
  margin: auto;
  width: 900px;
  background: white!important;
  padding: 45px!important;;
}

#font{
  font-size: 14px;
  font-weight: 500;
}

#bold{
  font-weight: 700;
}

#org{
  font-size: 14px;
  margin-top: -15px;
  font-weight: 800;
  float: left;
  width: 100%;
}
.otherDetails{
  margin-top: 10px;
  float: left;
}
.otherDetails p{
  float: left;
  margin-top: -15px;
  width: 100%;
}
#head{
  background-color: #F39B3E;
  font-size: 12px!important;
  font-weight: 700;
}

.otherDetails p{
  font-weight: 600;
}

#table4 tr th{
  font-weight: 500;
  font-size: 14px;

  
 
}

.parent .container-four-right {
  margin-left: 0px;
  text-align: left;
  margin-top: -5px;

}


table#table4 {
  margin-top: -16px;
  margin-left: -6px;
  border-left: none!important;
  width: 97.3%;
  margin-left: 0px;
  margin-top: 10px;
}
table#table4 tr{
  border-left: none;
  border-top: none;
}

.parent #table4 tr, .parent th {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-right: 1px solid black;
  padding-top: 28px;
  text-align: left;
  font-feature-settings: inherit;
  font-variant: inherit;
  border-left: none;
  border-right: none;
}

.parent #table4 tr, .parent th:last-child{
  border-bottom: none;
}
#table4 tr th:last-child{
  border-bottom: none;
}
#table4 tr th{
  border-bottom: none;
}

#table4 tr th {
  border-bottom: none;
  border-right: solid black 1px;
}
#table4 tr th:nth-child(2){
  border-right: none;
}
.qrCode canvas {
  width: 75px!important;
  height: 75px!important;
  margin-top: -20px;
  margin-right: 10px;
  margin-bottom: -5px;
}
.qrCode{
  float: right;
}
#auth{
  margin-top: 40px;
}
.parent .Table-container-two p img {
  height: 53px;
  object-fit: contain;
  margin-left: -20px;
}
.topCon{
  overflow: hidden;
  height: 100px;
}
#comdetails{
  margin-top: 46px;
}

.Table-container-one {
  border: solid black 1px;
}

#words{
  text-transform: capitalize;
  font-weight: 900;

}

h6#border {
   
  padding: 10px;
  margin-bottom: -11px;
  width: 100%;
  margin-top: -1px;
}
.product select {
  word-wrap: normal;
  padding: 8px;
  border: solid #dee2e6 2px;
  border-radius: 6px;
}
.parent .Table-container-six td, .parent .Table-container-six th {
  text-align: center !important;
}
.parent .Table-container-six th{
  font-weight: 400;
  font-size: 14px !important;
}
.parent .Table-container-six td{
  font-size: 18px !important;
}


.after-div{
  width: 100%;
  float: left;
  margin-top: 20px;
}
th:first-child,
    td:first-child {
        width: 20px;
    }

    textarea#textArea {
      width: 100%;
      border-radius: 10px;
      padding: 10px;
  }

  .Table-container-five th {
    text-align: center;
}
.Table-container-five td {
  text-align: center;
}
.parent .container-four-right {
  margin-left: 0px;
  text-align: left;
  margin-top: -5px;
  WIDTH: 48%;
}
.container-four-right th:first-child {
  width: 50%;
}
.parent .Table-container-two p img {
  height: 53px;
  object-fit: contain;
  margin-left: -20px;
  height: 80px;
  margin-bottom: 10px;
}
.invoice-tbl th, .invoice-tbl td{
  text-align: center!important;
}
table.purchasetable th {
  text-align: left!important;
}
.parent .container-two-right {
  position: relative;
  right: 20px;
  padding-left: 11px;
}

#p {
  font-size: 12px!important;
  margin-top: 40px;
  padding: 10px;
  float: left;
  width: 100%;
  position: relative;
}

.filter {
  float: left;
  padding: 10px;
  width: 80%;
  display: flex;
  margin-left:-10px;
  flex-direction: row;
  align-items:center;
  margin-bottom:40px;
}
.filter input ,.filter select{
  margin:4px!important;
  font-size: 14px;
}
.filter button{
width:100px;
height:40px;
padding:0px;
padding-left:20px;
padding-right:20px;
}
.table th {
  color: black !important;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: inherit;
}

table td {
  text-transform: capitalize;
}
#draft {
  background-color: rgba(82, 102, 235, 0.10196078431372549)!important;
  margin-top: 0px;
  margin-left: 0px !important;
  float: left;
  border-radius: 4px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight:500;
}

#cancelled,#hold {
  background-color: rgba(182, 12, 35, 0.1019607431372549)!important;
  margin-top: 0px;
  margin-left: 0px !important;
  float: left;
  border-radius: 4px;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight:500;
}
span#complete ,#active{
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgba(196,80,0,0.10196078431372549);
  border-radius:4px;
  font-size: 12px;
  font-weight:500;
}
button#draft {
  margin-top: 48px;
  padding: 6px;
  background-color: grey !important;
}
button#cancelled {
  margin-top: 48px;
  padding: 6px;
  margin-left:10px;
  background-color: red !important;
  border:none;
}

ul.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 26px;
  
}
a.page-link{
  color: black !important;
  font-size: 14px;
}
span.page-link {
  font-size: 14px;
}
tbody td {
    font-size: 14px;;
}
.settings input.form-control {
  width: 240%;
  background: #f9f9ff;
  border: none;
  font-size: 14px;
}
.col-sm.product label {
   font-size: 12px;
  font-weight: 500;
  border-radius:0px;
  text-transform:capitalize;

}
.col-sm.product {
  display: flex;

}

.product select {
  word-wrap: normal;
  padding: 11px;
  border: solid #dee2e6 1px;
  border-radius: 6px;
}
.col-sm.product {
  display: flex
;
  flex-direction: column-reverse;
  align-items: self-end;
  justify-content: flex-end;
}
.product select,.product input{
  word-wrap: normal;
  padding: 11px;
  border: solid #dee2e6 1px;
  border-radius: 6px;
   font-size: 12px;
}
.product textarea{
  width:200px;
  font-size: 14px;
  padding:8px;
}

.col-sm.product label {
  font-size: 8px;
  font-weight: 500;
  border-radius: 0px !important;
  text-transform: capitalize;
  padding: 3px;
}
.col-sm.added {
  display: flex
;
  flex-direction: row;
}
.col-sm.added label{
  font-size:8px!important;
}
.col-sm.added input {
  width: 60px;
}
.col-sm.added textarea {
  width: 100px;
}
.menu-items p {
  margin-top: 16px;
  font-size: 16px;
}
/* Move Money Button */
.move-money-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.move-money-dropdown {
  position: absolute;
  top: 40px;
  right: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.move-money-dropdown a {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  color: #333;
}

.move-money-dropdown a:hover {
  background-color: #f0f0f0;
}

/* User Dropdown */
.user-dropdown {
  position: absolute;
  top: 40px;
  right: 20px;
  background-color: white;
  border: 0px solid #ccc;
  border-radius: 5px;
  margin-top:20px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-dropdown a {
  display: block;
  padding: 8px 15px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-weight:500;
  line-height:1rem;
}

span#admin {
  font-size: 11px;
  margin-left: 13px;
  padding: 4px;
  border: solid #f8f8f8 1px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  background: #f8f8f8;
}
span#newline {
  width: 100%;
  background: #f8f8f8;
  float: left;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.payment_summary{
  display:flex;
  flex-direction:row;
  margin-left:-10px;
}
.summary{
  width:33%;
  margin:10px;
  border:solid rgba(82, 102, 235, 0.10196078431372549) 2px;
  padding:20px;
  border-radius:20px;
}
.ff{
  width:47%;
  background:#fff;
  margin:10px;
  border-radius:20px;
  box-shadow:0 0 2px 0 rgba(175,178,206,.56),0 1px 4px 0 rgba(4,4,52,.1)
}
.ff p{
  font-weight:700;
  margin-left:12px;
  margin-top:15px;
  font-size:12px;
  color:#1f1f1f;
  opacity:0.8;
}
.fff{
  width:17.78%;
  background:#fff;
  padding:20px;
  margin:10px;
  border-radius:20px;
  box-shadow:0 0 2px 0 rgba(175,178,206,.56),0 1px 4px 0 rgba(4,4,52,.1)
}
.fff p{
  font-weight:700;
  font-size:12px;
  color:#1f1f1f;
  opacity:0.8;
}
.container.mt-5 h2 {
  margin-bottom: 43px;
  font-size: 22px;
  font-weight: 600;
}

th.ant-table-cell {
  width: 50px;
}
.range {
  padding: 0!important;
}