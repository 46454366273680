@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    font-family: 'Montserrat', sans-serif!important;
}



.login-box{
    background-color: #fff;
    width: 25%;
    margin-left: 0%;
    padding: 10px;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.10);

}

.textinput{
    width: 100%;
    flex-direction: column;
    display: flex;
    margin-top: 15px;
}

.textinput label{
    font-weight: 500;
}
.textinput input{
    border: none;
    border-bottom: solid #F39B3E 1px;
    height: 40px;
   
}

.login-heading h1{
    text-align: left;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
}
.login-heading p{
    margin-bottom: 10px;
}
.inputBtn input{
    background-color: #5A31F4;
    border: none;
    margin-top: 20px;
    width: 100%;
    padding: 4px;
    color: #fff;
    border-radius: 10px;
}

.textinput input[type="text"], input[type="text"]:focus {
    background: white;
    outline-width: 3px;
    border-bottom: solid #F39B3E 1px!important;
}

.inputBtn  input[type="email"], input[type="text"]:focus {
    background: white;
    outline-width: 3px;
    outline: 0px;
    border-bottom: solid #F39B3E 1px!important;
}

.textinput input[type="password"], input[type="text"]:focus {
    background: white;
    outline-width: 3px;
    outline: 0px;
    border-bottom: solid #F39B3E 1px!important;
}

.forgotPassword a{
    color: blue;
    font-size: 14px;
    text-align: right;
    width: 100%;
    float: right;
    margin-top: 10px;
    text-decoration: none;
}


.newAccount a{
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 30px;
    color: #000;
    text-decoration: none;
}


.container-fluid {
    background: white;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.05);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 80px;
    padding-left: 40px;
  }

  .bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: white!important;
    padding: 10px;
    padding-left: 40px;
    padding-right: 50px;
}

a.navbar-brand {
    font-size: 16px;
    margin-left: 2px;
    font-weight: 500;
}
form.d-flex.search {
    background: #f9f9f9;

}

.search input.form-control.me-2 {
    border: solid #f9f9f9 2px;
}
.search button.btn.btn-outline-success {
    border: none;
    background: black;
    color: white;
}

.navbar-brand img {
    width: 130px;
}

.mt-5 {
    margin-top: 1rem!important;
}


.mb-5 {
    margin-bottom: 0rem!important;
}
h2.font-weight-bold.mb-5 {
    font-size: 22px;
    float: left;
    margin-top: 20px;
    font-weight: 600;
}
.logo h1 {
    font-size: 22px;
}

button#downlaod {
    margin-left: 10px;
    float: right;
    position: relative;
    margin-right: 10px;
}

body{
    background-color: #F0F6FD!important;
  }

  button.btn.btn-dark.new {
    background: #408dfb;
    border: none;
    margin-top: -50px;
    margin-bottom: 30px;
    color: white;
    font-weight: 600;
}
  
  .bg-light{
    background-color: #f9f9f9!important;
  }

  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: #F0F6FD!important;
    border: none;
}
.table>:not(caption)>*>* {
    border-bottom-width: 0px;
}
button.btn.btn-dark {
    background: transparent;
    border: navajowhite;
    color: black;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: #F0F6FD!important;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
}
.table>:not(caption)>*>* {
    border-bottom-width: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #f9f9f9;
}
.table th {
 background-color: #f9f9f9;
 padding-top: 10px;
 padding-bottom: 10px;
}
.filter{
    float: left;
    padding: 10px;
    width: 80%;
}
.filter select{
    padding: 10px;
    border: solid #f0f0f0 2px;
    width: 300px;
    border-radius:8px ;
}


.filter input{
    padding: 10px;
    border: solid #f0f0f0 2px;
    width: 300px;
    border-radius:8px ;
}

.filter input[type="date"]:not(.has-value):before{
    color: rgb(133, 133, 133);
    content: attr(placeholder);
  }

.filter button{
    background-color: #000;
    padding: 10px;
    margin-left: 4px;
    border: none;
    color: white;
    width: 100px;
    border-radius:8px ;
}
.loader{
    background-image: url('../images/bill.svg');
    background-size: contain;
    width: 100vh;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: red;
    z-index: 9999999999;

}
.companyheadr{
    background-image: url('../images/bill.svg');
    background-size: contain;
    width: 100%;
    height: 100px;
    padding: 20px;
    margin-top: -10px;
    margin-left: -10px;
  
  }
  .companyheadr h1{
    font-size: 22px;

  }

  .icon{
    width: 70px;
    height: 70px;
    background-color: #408dfb;
    float: left;
    margin-right: 20px;
    padding: 15px;
    border-radius: 10px;
  }

  .icon img{
    width: 100%;
  }

  button#controlled-tab-example-tab-dashboard ,button#controlled-tab-example-tab-documents{
    border: none;
    color: black;
}
button#controlled-tab-example-tab-updates {
    border: none;
}


button#controlled-tab-example-tab-updates {
    color: black;
}
.nav-item .active{
       color: #408dfb!important;
        border-bottom: solid #408dfb 2px!important;
        font-weight: 500;
    }

    HSN{
        border: solid #f0f0f0 1px;
        padding: 0px;
        border-radius: 10px;
        overflow: hidden;
        width: 80%;
    }

    HSN p{
        background-color: #f0f0f0;
        padding: 20px;
    }

    .bill-inshights-tabs{
        width: 25%;
        padding: 10px;
        float: left;
    }

    .bill-inshights-tabs p{
        background-color: transparent;
        padding: 0px;
    }

   
.bill-inshights-tabs h2{
    font-size: 22px;
    font-weight: 600;
}
#total{
    cursor: pointer;
}

#progress{
    margin-top: 20px;
    margin-bottom: 20px;
}
#total{
    font-weight: 700;
}
button#controlled-tab-example-tab-announcements {
    border: none;
    color:black;
}
button#controlled-tab-example-tab-explore {
    border: none;
    color: black;
}
button#controlled-tab-example-tab-pricing {
    border: none;
    color: black;
}
button#controlled-tab-example-tab-apps {
    border: none;
    color: black;
}

button#controlled-tab-example-tab-personal,button#controlled-tab-example-tab-financial,button#controlled-tab-example-tab-banking,button#controlled-tab-example-tab-legal,button#controlled-tab-example-tab-orgd,
button#controlled-tab-example-tab-other,button#controlled-tab-example-tab-invoicing,button#controlled-tab-example-tab-item,button#controlled-tab-example-tab-tax,button#controlled-tab-example-tab-transactions,button#controlled-tab-example-tab-templates
,button#controlled-tab-example-tab-transport{
    border: none;
    color: black;
}

.settings table>:not(caption)>*>* {
    border-bottom-width: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: transparent!important;
    border: none!important;
    font-size: 16px;
    font-weight: 500;
}
.settings .table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0;
    border: none;
   
}

.settings .table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0;
    margin-top: 10px;
    float: left;
    width: 100%;
}

.settings .table>:not(caption)>*>* {
    border-bottom-width: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #f9f9f9;
    float: left;
    width: 30%;
}
.settings input.form-control {
    width: 300%;
}
.settings,.product{
    border: none;
}

button.btn.btn-primary {
    float: left;
    width: 120px;
    margin-top: 10px;
}


form .btn.btn-primary {
    float: left;
    width: 120px;
    margin-left: -55px;
    margin-top: 10px;
}


.customer-invoice-tabs{
    width: 100%;
    float: left;
}

#add{
    width: 40px;
    height: 40px;
    padding: 0px;
    margin-left: 20px;
    opacity: 0.7;
    border-radius: 100px;
}


label.list-group-item {
    width: 33%;
    float: left;
}
.col-sm.added label {
    margin-left: 18px;
}


.added-items{
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 20px;
}
.added-items h6 {
    font-size: 1rem;
    margin-left: 20px;
    width: 100%;
    float: left;
    margin-bottom: 23px;
}

#draft{
    background-color: grey!important;
    border: none;
    margin-top: 48px;
    margin-left: 0px!important;
    float: left;
    border: none;
}

#final{
    border: none;
    margin-top: 55x;
    float: left;
    margin-left: 10px!important;
}

span#activeIcon {
    float: right;
    margin-top: -40px;
    width: 30px;
    background: #f9f9f9;
    text-align: center;
    padding: 4px;
    margin-left: 80%;
    height: 30px;
    border-radius: 40px;
    color: #408dfb;
}

.head-title{
    width: 100%;
    float: left;
    padding: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.demo{
    margin-left: 60px;
    padding: 20px;
    float: left;
    background-color: #e8f2ff;
    border-radius: 10px;
    width: 70%;
}
.demo-video{
    width: 25%;
    float: left;
}

.demo-title h2{
    font-size: 20px;
    font-weight: 500;
}

.demo-title p{
    font-size:18px;
   
}

.demo-video iframe{
    width: 96%;
    height: 120px;
    margin-right: 20px;
    border-radius: 10px;
}
button#controlled-tab-example-tab-files {
    border: navajowhite;
    color: black;
}

table button {
    border: none;
    margin-top: 10px;
    background: black;
    color: white;
    width: 140px;
    border-radius: 100px;
}
.settings input.form-control {
    width: 240%;
}

.parent .App-container {
    width: 900px;
    display: flex;
    margin: auto;
    border: solid black 0.4px;
    margin-top: 10px;
    padding-top: 20px;
    background: white;
}

button.btn.btn-secondary {
    width: 90%;
    margin-left: 5%;
    background: black;
    padding: 4px;
    border: none;
    border-radius: 32px;
}
.modal-content {
    width: 172%;
    margin-left: -36%;
}
.modal-content button{
    margin-left: 0px!important;
}

.settings input.form-control {
    width: 240%;
    background: #f9f9ff;
    border: none;
}


.create .form-control  {
    border: none;
    border-bottom: solid #F39B3E 1px;
    height: 40px;
    border-radius:0 ;
    margin-top: 10px;
}

#strong{
    font-weight: 600;
}

.create button.btn.btn-primary {
    width: 122%;
    margin-left: 2px;
    margin-bottom: 15px;
    background: black;
    border: none;
    padding: 4px;
    border-radius: 10px;
}

.create button.btn.btn-secondary {
    background: transparent;
    color: black;
}
.col-sm.added input {
    width: 131px;
}
button.btn.btn-danger.btn-sm.ml-2 {
    margin-left: 10px;
}

#share {
    margin-left: 10px;
    background-color: #000;
}

td{
    vertical-align: middle;
}
.challan.Table-container-two p {
    border: none!important;
    width: 110%;
    text-align: center;
    margin-left: 0px;
    font-size: 14px;
    line-height: 2px;
  }

  #challanOrg{
    font-weight: 600;
    margin-top: -30px!important;
  }

  .challan.Table-container-two {
    display: flex!important;
    flex-direction: column!important;
    line-height: 10px;
  
}
.challanTableOne {
    display: flex;
    flex-direction: row;
    width: 100%;
}
#receiver{
    width: 100%;
    border: solid black 1px;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
}
.container-four-left.challanTableTwo{
    width: 100%;
}
.challanTableTwo p{
 line-height: 1px;
 padding-left: 10px;
 font-size: 14px;
}
#center{
    width:50rem;
    position: absolute;
    opacity: 0.1;
}

th#highlight {
  
    font-weight: 800 !important;
}
span#highlighttd{
   
    font-weight: 800 !important;
}

tr#bank th {
    width: 50%;
}

.container-two-left {
    width: 70%;
}

span#singleline {
    width: 64%;
    float: left;
    margin-left: 10px;
    margin-top: -14px;
    margin-bottom: 10px;
}

.container-two-left h6 {
    width: 100%;
    float: left;
}
span#singlelineleft {
    float: left;
    width: 40%;
    word-wrap: break-word; /* For non-standard-compliant browsers */
    overflow-wrap: break-word; /* For standard-compliant browsers */
}

.container-two-left {
    width: 140%;
}
span#singleline {
    margin-bottom: 10px;
    width: 50%;
    float: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin-left: 10px;
    margin-top: -13px;
}
.parent .last-container-left {
    font-size: 16px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    float: left;
    margin-left: -32px;
    width: 77%;
    flex-direction: column;
}
.last-container.prop{
    display: flex;
    flex-direction: row;
    margin-top: -68px!important;
    margin-right: 21px;
    float: right;
}

.companyaddress{
    width: 100%;
    margin-top: 20px;
}

.companyaddress h4{
    font-size: 18px;
    margin-bottom: 40px;
}
.billForm{
   height: 100%;
    width: 45%;
}
.billFormLeft{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    flex-direction: column;

}
.billForm .billin {
    height: 33%;
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative; 
}

.billForm .billin::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 45%; 
    border-bottom: 1px solid black;
    margin-top: 1px;
}

.billin p {
    margin-left: 0px;
    float: right;
    width: 62%;
   height: 30px;
   font-size: 0px;
   
}
.billin.last-child p{
    border-bottom: none;
}
.billin label {
    float: left;
    width: 77%;
    line-height: 40px;
    border-right: solid black 1px;
}
.billFormLeft p{
    width: 100%;
    text-align: center;
    line-height: 10px;
 
} 
.justify-cont{
    display: flex;
    flex-direction: row;
    overflow: hidden;
}




.container-two-left {
    width: 140%;
    min-width: 50%;
    padding-left: 0px;
    margin-bottom: 20px;
    margin-right: 18px;
}
.parent .container-two-right h6 {
    font-size: 14px;
    line-height: 18px;
}

.parent .container-one-left img {
    margin: auto;
    height: 70px;
    object-fit: cover;
    width: 114%;
    margin-left: -57px;
}
.top-po .container-one-left {
    width: 40%;
}
.top-po .container-one-right.personal {
    width: 60%;
}
img#pologo {
    height: auto;
}

.fonts{
      font-size: 14px;;
}
.parent .Table-container-six th {
    font-weight: 400;
      font-size: 14px;;
}

.parent .container-two-right {
    position: relative;
    right: 20px;
    min-width: 50%;
}

#terms{
    font-weight: 400;
}
.fonts #bold, .fonts h1,.fonts h2,.fonts h3, .fonts h4, .fonts h5, .fonts span, .fonts h6, .fonts p,.fonts th, .fonts td {
    font-size: 12px!important;
}
.bottom-details {
    display: none;
}

@media print {
    .bottom-details {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
         font-size: 12px;
        display: none;
    }
    #head {
        background-color: #F39B3E!important;
    }
    .fonts #bold, .fonts h1,.fonts h2,.fonts h3, .fonts h4, .fonts h5, .fonts span, .fonts h6, .fonts p,.fonts th, .fonts td {
        font-size: 12px!important;
    }
   
    .print-container {
        page-break-after: avoid;
        
      }
 
}

#bottom{
    font-size: 14px;
     width: 100%;
     margin-top: 100px;
    bottom: 0;
    text-wrap: wrap;
    width: 100%;
    float: left;
}


@media print {
    #bottom{
        font-size: 14px;
        position: relative;
        bottom: 0;
        text-wrap: wrap;
        width: 100%;
    }
}
.biiling-inshights{
    width:100%;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
}
.card.p-3.shadow-sm {
    border: none;
    box-shadow: none;
}

.login-heading img {
    margin-left: -10px;
    display: flex
;
    float: left;
    width: 136px;
    margin-bottom: 10px;
    padding: 0px;
}

.login-heading{
    display: flex;
    flex-direction:column;
}
.send_btns{
    display:flex;
    flex-direction:row;
    margin-top:20px;
    gap:10;
  }
  .send_btns button{
   border:none;
   margin:10px;
   padding-left:20px;
   padding-right:20px;
   border-radius:20px;
   font-size:12px;
  }

  .send_btns button.active{
    border:none;
    margin:10px;
    background:#5A31F4;
    color:white;
   }

   #controlled-tab-example{
    margin-top:40px;
    float:left;
    display:none;
   }
   button.ant-btn.ant-btn-primary.ant-btn-color-primary.ant-btn-variant-solid {
    width: 100%;
    margin-top: 24px;
    border-radius: 20px;
}
input.ant-input.ant-input-filled.ant-otp-input{
    background-color:#f5f5f5!important;
    border-bottom:2px #f5f5f5 solid!important;
    border-radius:10px;
  }
  input[type="email"]:disabled {
    background-color: #f5f5f5!important; 
    color: #a0a0a0;
    border-radius:10px;
    border-bottom:2px #f5f5f5 solid!important;
    padding-left:10px;
    opacity:0.8;
  }  

  span#resend {
    float: right;
    color: blue;
    margin-top: 4px;
    justify-content: flex-end;
    display: flex
;
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
}

#small{
    font-size:10px;
    font-weight:600;
    margin-left:15px;
    display:flex;
    justify-content:left;
    opacity:0.6;
}

ul.pagination {
    display: flex
;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 26px;
    width: 100%;
}

.ant-empty {
    display: flex
;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.ant-col label {
    font-weight: 500 !important;
}
td.ant-table-cell{
    padding: 0px !important;
    padding-bottom: 10px !important;
}
th.ant-table-cell {
    padding: 0px !important;
    padding-bottom: 10px !important;
    padding-top:10px!important;
}